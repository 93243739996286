import '../css/login_comp.css';
import { hideLoading, deepCopy } from '../utils/utils';

import searchIcon from '../icons/search.svg';
import mailIcon from '../icons/mail.svg';
import lockIcon from '../icons/lock.svg';
import visibilityIcon from '../icons/visibility.svg';
import edunifyIcon from '../icons/edunify-text-blue.svg';

export default {
	oninit: (vnode) => {
		vnode.state.input_type = 'password'
		vnode.state.togglePassword = function() {
			if(vnode.state.input_type == 'password'){
				vnode.state.input_type = 'text'
			} else {
				vnode.state.input_type = 'password'
			}
		}
	},
	oncreate: async(vnode) => {
		hideLoading()
	},  
	view: (vnode) => {
		return m('div', { class: 'login-cont' }, [
			m('div', { class:'login-info-cont' }, [
				m('img', { src: edunifyIcon }),
				m('h2', 'Log In'),
				m('p', 'To continue to the Edunify onboarding'),
			]),
			m('div', { class:'login-input-cont' }, [
				m('div', [
					m('div', { class: 'login-input-field' }, [
						m('label', { for: 'f-email' }, 'EMAIL'),
						m('input', { 
							id: 'f-email',
							class: 'form-control',
							placeholder: ""
						}),
						m('img', {src: mailIcon}),
						m('span', 'Enter your email')
					]),
					m('div', { class: 'login-input-field' }, [
						m('label', { for: 'f-password' }, 'PASSWORD'),
						m('input', { 
							id: 'f-password',
							class: 'form-control',
							type: vnode.state.input_type,
							placeholder: ""
						}),
						m('img', { src: lockIcon }),
						m('span', 'Enter your password'),
					]),
					m('img', { class: 'visibility-icon', src: visibilityIcon, onclick: vnode.state.togglePassword }),
					// m('span', 'Forgot Password'),
				]),
				m('button', { id: 'form-login-btn' }, 'Continue'),
			])
		])
	},
};
